body{
    background: #fff;
}
.img-wrapper{
   img{
       display: inline-block;
       width: 100%;
       margin-top: 0.09rem;
   } 
   
}
.input-wrapper{
    position: relative;
    margin-left: 0.14rem;
    margin-right: 0.14rem;
    height: 0.4rem;
    border:1px solid rgba(198,30,77,1);
    border-radius: 0.2rem;
    -webkit-tap-highlight-color:transparent;
    .input-main{
        outline: none;
        width: 2.7rem;
        -webkit-appearance: none;
        background-color: transparent;
        line-height: 1.5;
        border: none;
        padding: 0 0 0 0.4rem;
        height: 0.4rem;
        box-sizing: border-box;
        font-size: 0.14rem;
    }
    input::-webkit-input-placeholder {
        color: #BBB;
        font-size: 0.14rem;
        padding: 0;
        font-family:PingFangSC-Regular,PingFang SC;
    }

    .am-search-synthetic-ph-icon {
        z-index: 2;
        position: absolute;
        left: 0.16rem;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 5px;
        width: 15px;
        height: 15px;
        vertical-align: -2.5px;
        background-repeat: no-repeat;
        background-size: 15px auto;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'38'%20height%3D'36'%20viewBox%3D'0%200%2038%2036'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M29.05%2025.23a15.81%2015.81%200%200%200%203.004-9.294c0-8.8-7.17-15.934-16.017-15.934C7.192.002.02%207.136.02%2015.936c0%208.802%207.172%2015.937%2016.017%2015.937%204.148%200%207.928-1.569%2010.772-4.143l8.873%208.232%202.296-2.45-8.927-8.282zM16.2%2028.933c-7.19%200-13.04-5.788-13.04-12.903%200-7.113%205.85-12.904%2013.04-12.904%207.19%200%2012.9%205.79%2012.9%2012.904%200%207.115-5.71%2012.903-12.9%2012.903z'%20fill%3D'%23bbb'%20fill-rule%3D'evenodd'%2F%3E%3C%2Fsvg%3E");
    }
    .am-search-clear {
        cursor: pointer;
        box-sizing: content-box;
        position: absolute;
        display: none;
        z-index: 3;
        width: 15px;
        height: 15px;
        padding: 6.5px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: 0.1rem;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        transition: all 0.3s;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2028'%3E%3Ccircle%20cx%3D'14'%20cy%3D'14'%20r%3D'14'%20fill%3D'%23ccc'%2F%3E%3Cline%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20stroke-miterlimit%3D'10'%20x1%3D'8'%20y1%3D'8'%20x2%3D'20'%20y2%3D'20'%2F%3E%3Cline%20fill%3D'none'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20stroke-miterlimit%3D'10'%20x1%3D'20'%20y1%3D'8'%20x2%3D'8'%20y2%3D'20'%2F%3E%3C%2Fsvg%3E");
    }
    .am-search-clear-show{
        display: block;
    }
    .input-search-btn{
        position: absolute;
        right: -0.01rem;
        top: 0rem;
        width: 0.76rem;
        height: 100%;
        line-height: 0.4rem;
        background: rgba(198,30,77,1);
        color: #fff;
        border-radius: 0.2rem;
        text-align: center;
    }
    
}
// 搜索弹窗
.search-modal-wrapper{
    height: 100vh;
    .search-input-wrapper{
        padding-top: 0.07rem;
        padding-bottom: 0.07rem;
    }
    .input-wrapper{
        display: inline-block;
        width: 3.06rem;
        margin-right: 0.1rem;
        margin-left: 0rem;
    }
    .cancel-text{
        display: inline-block;
        color: #8D8D8D;
        font-size: 0.15rem;
        height: 0.4rem;
        line-height: 0.4rem;
    }
    .input-main{
        padding-left: 0.25rem;
    }
}

// 搜索时的结果
.search-result-wrapper{
    margin-left: 0.15rem;
    margin-right: 0.15rem;
    color: #333;
    font-size: 0.14rem;
    text-align: left;
    .search-result-item{
        line-height: 0.2rem;
        padding-top: 0.12rem;
        padding-bottom: 0.12rem;
        border-top: 0.01rem solid #F4F4F4;
        &:nth-child(1){
            border-top: none;
        }
    }
}

// 备注
.remark-wrapper{
    display: flex;
    color: #7F7F7F;
    margin: 0.14rem 0.14rem 0 0.14rem;
    line-height: 0.24rem;
    font-size: 0.12rem;

}
.result-wrapper{
    color: #333;
    margin: 0.16rem 0.14rem 0 0.14rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    font-family:PingFangSC-Regular,PingFang SC;
    .result-remark{
        color: #666;
        font-weight:500;
        padding-bottom: 0.15rem;
    }
    .title{
        position: relative;
        &::before{
            position: absolute;
            left: 0rem;
            top: 0.02rem;
            content: '';
            width:2px;
            height:0.14rem;
            background:rgba(198,30,77,1);
            border-radius:1px;
        }
    }
    .detail{
        padding-left: 0.08rem;
        margin-top: 0.08rem;
    }
    .between{
        
        display: flex;
        justify-content: space-between;
    }
    .all{
        font-weight:500;
        font-size:0.16rem;
        font-family:PingFangSC-Medium,PingFang SC;
    }
}
input[type="search"]::-webkit-search-cancel-button{
    display: none;
}

.no-data-wrapper{
    text-align: center;
    img{
        display: inline-block;
        width: 1.87rem;
    }
}




  


@brand-primary: #00BBB6;@brand-primary-tap: #00BBB6;